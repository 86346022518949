<template>
  <v-container fluid class="liProjects body-2 pa-0 pb-5 bg-dark" style="margin-top: 64px">
    <template v-if="projects != null">
      <!-- ALERT -->
      <v-snackbar v-model="snackbar" elevation="20" :timeout="snackbarTimeout" absolute top right :color="snackbarColor">
        {{ snackbarText }}
      </v-snackbar>
      <!-- /. ALERT -->
      <!-- ADD -->
      <v-tooltip right v-if="!addProjectSheet && !loading && !rule_noProjects()">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" dark fab absolute bottom right style="z-index: 3; position: fixed !important; bottom: 50px; right: 50px" class="ml-3" @click="addProject">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ appConfig.texts.projects.add }}</span>
      </v-tooltip>
      <!-- /. ADD -->
      <!-- HEADER -->
      <v-sheet elevation="0" class="pa-0 mb-5">
        <div class="d-flex pt-0 justify-content-between body-4 w-100" style="min-height: 70px">
          <h3 class="text-h5 mb-0 px-3 w-100 align-self-center">
            <span v-html="appConfig.texts.projects.title" />
            <!-- ADD -->
            <v-tooltip right v-if="!addProjectSheet">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="white" @click.prevent="addProject" elevation="0" light square class="ml-5" style="max-width: 70px; min-width: unset; padding: 0px; width: 130px; min-height: 70px; border-right: 1px solid #e0e0e0 !important; border-left: 1px solid #e0e0e0 !important">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ appConfig.texts.projects.add }}</span>
            </v-tooltip>
            <!-- /. ADD -->
          </h3>
          <div class="mr-2 pt-2" style="width: calc(100% - 200px); min-width: 200px">
            <label>{{ appConfig.texts.projects.search }}</label>
            <v-text-field v-model="search" clearable hide-details dense single-line append-icon="mdi-magnify" />
          </div>
        </div>
      </v-sheet>
      <!-- /.HEADER -->
      <!-- PROJECTS -->
      <v-sheet class="ma-md-10 ma-5 body-2">
        <v-simple-table v-if="!loading && projects != null && projects.length != 0">
          <template v-slot:default>
            <thead>
              <tr class="">
                <th class="text-left">
                  {{ appConfig.texts.projects.table[0] }}
                </th>
                <th class="text-right">
                  {{ appConfig.texts.projects.table[1] }}
                </th>
              </tr>
            </thead>
            <draggable v-model="projects" tag="tbody" group="dividers" @change="changePos" handle=".handle">
              <tr v-for="project in projects" :key="project.id" @click.prevent="$router.push(`/project/${project.identifier}`)" class="cursor-pointer liProjectsTabItem">
                <td class="pl-1">
                  <div class="d-flex w-100">
                    <v-icon class="mx-1 handle cursor-grab" dense>mdi-drag-vertical</v-icon>
                    <span class="my-auto d-block font-weight-bold" v-html="search != '' ? $options.filters.highlightText(project.name, search) : project.name" />
                  </div>
                </td>
                <td class="text-right">
                  <div class="actions rounded-pill">
                    <!-- START  -- / REMOVE PROJECT -->
                    <v-menu :close-on-content-click="true">
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip left>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon small class="showMe" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                              <v-icon small> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ appConfig.texts.projects.tableRemove }}</span>
                        </v-tooltip>
                      </template>
                      <v-card style="width: 280px; max-width: 280px">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content v-html="appConfig.texts.projects.confirmText" />
                          </v-list-item>
                        </v-list>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click.prevent="menu = false">
                            {{ appConfig.texts.projects.cancel }}
                          </v-btn>
                          <v-btn color="primary" text @click.prevent="removeProject(project)">
                            {{ appConfig.texts.projects.approve }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
              <!-- NO PROJECTS ALERT -->
              <tr v-if="projects.length == 0">
                <td colspan="2" class="pt-4">
                  <v-alert v-if="search == ''" border="left" class="body-2" type="error" elevation="2">
                    <span class="mr-3">{{ appConfig.texts.projects.noProjects }}</span>
                  </v-alert>
                  <v-alert v-if="search != ''" border="left" class="body-2" type="warning" elevation="0">
                    <span class="mr-3">{{ appConfig.texts.projects.noProjectsSearch }}</span>
                  </v-alert>
                </td>
              </tr>
              <!-- /. NO PROJECTS ALERT -->
              <!-- </transition-group> -->
            </draggable>
          </template>
        </v-simple-table>
        <v-row v-else-if="rule_noProjects()" style="min-height: calc(100vh - 900px)">
          <div class="ma-auto text-center">
            <div style="max-width: 600px">
              <span v-html="appConfig.texts.projects.noProjectsNewAccount" />
            </div>
            <v-btn color="primary" dark fab @click="addProject">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-row v-else style="min-height: calc(100vh - 900px)">
          <v-progress-circular indeterminate color="primary" class="ma-auto"></v-progress-circular>
        </v-row>
      </v-sheet>
      <!-- /.END PROJECTS -->
      <!-- NEW PROJECT -->
      <v-bottom-sheet v-model="addProjectSheet" inset max-width="95%">
        <v-sheet class="body-3" color="grey lighten-4" style="position: relative">
          <v-btn fab top small absolute right color="primary" @click="addProjectSheet = !addProjectSheet"> <v-icon small>mdi-close</v-icon></v-btn>
          <v-sheet color="grey lighten-4 liAddProjectSheet">
            <perfect-scrollbar>
              <v-form v-model="valid" ref="addProjectForm">
                <v-container fluid>
                  <v-sheet class="pt-5 px-5 d-flex">
                    <span class="w-75 pr-5">
                      <label v-html="appConfig.texts.projects.newProject.name" />
                      <v-text-field dense v-model="newProject.name" :rules="defaultRules" required></v-text-field>
                    </span>
                    <span class="w-25">
                      <label v-html="appConfig.texts.projects.newProject.reference_number" />
                      <v-text-field dense v-model="newProject.reference_number" required></v-text-field>
                    </span>
                  </v-sheet>
                  <v-sheet class="mt-5 pt-5 px-5">
                    <div class="d-flex w-100 border-bottom pb-3">
                      <label v-html="appConfig.texts.projects.newProject.external" />
                      <liInfoModalBtn infoIdentifier="external" styleClasses="ml-auto" />
                    </div>
                    <v-radio-group dense v-model="newProject.external" row :rules="defaultRules" required>
                      <template v-for="(option, index) in appConfig.texts.projects.newProject.externalOptions">
                        <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                      </template>
                    </v-radio-group>
                    <template cols="12" v-if="newProject.external == '1' || newProject.external == '2'" class="py-0">
                      <div class="d-flex w-100 border-bottom mt-5 pb-3">
                        <label v-html="appConfig.texts.projects.newProject.lpl" />
                        <liInfoModalBtn infoIdentifier="LPL" styleClasses="ml-auto" />
                      </div>
                      <v-radio-group dense v-model="newProject.lpl" row :rules="defaultRules" required>
                        <template v-for="(option, index) in appConfig.texts.projects.newProject.lplOptions">
                          <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                        </template>
                      </v-radio-group>
                    </template>
                    <div class="d-flex w-100 border-bottom mt-5 pb-3">
                      <label v-html="appConfig.texts.projects.newProject.grid" />
                      <liInfoModalBtn infoIdentifier="grid" styleClasses="ml-auto" />
                    </div>
                    <v-radio-group dense v-model="newProject.grid" row :rules="defaultRules" required>
                      <template v-for="(option, index) in appConfig.texts.projects.newProject.gridOptions">
                        <v-radio :key="index" :label="option.name" :value="option.value" class="mr-10"></v-radio>
                      </template>
                    </v-radio-group>
                  </v-sheet>
                </v-container>
              </v-form>
            </perfect-scrollbar>
            <v-sheet color="grey lighten-4" class="pa-5 text-center">
              <v-btn elevation="0" :disabled="!valid" color="primary" class="mr-4" @click="submitProject">
                {{ appConfig.texts.projects.newProject.submit }}
              </v-btn>
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-bottom-sheet>
      <!-- /.END NEW PROJECT -->
    </template>
  </v-container>
</template>
<script>
import _ from "lodash";
import draggable from "vuedraggable";
export default {
  name: "Projects",
  title: "Projects",
  components: {
    draggable,
  },
  data() {
    return {
      defaultRules: [(v) => !!v || this.appConfig.texts.projects.required],
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      snackbarTimeout: 3000,
      valid: true,
      error: false,
      loading: false,
      search: "",
      addProjectSheet: false,
      addMainDivider: {
        identifier: this.generateUUID(),
        name: "",
        preAmp: "",
        ampere: "",
        subdividers: 0,
        critendgroups: 0,
      },
      newProject: {
        created_by: "",
        account: "",
        identifier: "",
        name: "",
        external: "",
        grid: "",
        reference_number: "",
        lpl: "",
        calc_project_items: [
          {
            identifier: "",
            shortCircuitResistance: "",
            ampere: "",
            preAmp: "",
            product: "",
            name: "",
          },
        ],
      },
    };
  },
  watch: {
    search(val) {
      if (val == null) {
        this.search = "";
      }
    },
    user(val) {
      if (val && this.projects == null) {
        this.getProjects();
        this.setupTitle();
      }
    },
  },
  computed: {
    projects: {
      get() {
        if (this.search != "") {
          return this.$store.getters["liDehnProject/getCollection"]("projects").filter((item) => {
            if (item.name.toLowerCase().includes(this.search.toLowerCase())) {
              return true;
            }
          });
        } else {
          return this.$store.getters["liDehnProject/getCollection"]("projects");
        }
      },
      set(val) {
        // console.log(val);
        val.forEach((item, index) => {
          item.sequence = index;
        });
        this.$store.dispatch("liDehnProject/MOVE_COLLECTION_ITEM", { tree: val, object: "_li_projects" }).then(() => {
          this.getProjects();
        });
        // return val;
      },
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
    mdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_md`);
    },
  },
  mounted() {
    if (this.projects === null) {
      this.error = true;
      if (this.user != null) {
        this.getProjects();
        this.setupTitle();
      }
    } else {
      this.setupTitle();
    }
  },
  methods: {
    changePos(val) {
      console.log("changePos", val);
    },
    rule_noProjects() {
      return !this.loading && (this.projects == null || this.projects.length == 0) ? true : false;
    },
    getProjects() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "dehn_projects",
          where: `status [=>] 1 |  account [=>] ${this.user.account}`,
          orderBy: "sequence ASC",
          select: "*",
        },
        collectionName: `projects`,
      });
    },
    addDividerItem() {
      this.newProject.calc_project_items = [...this.newProject.calc_project_items, _.cloneDeep(this.addMainDivider)];
      this.addMainDivider.identifier = this.generateUUID();
    },
    removeDividerItem(index) {
      this.newProject.calc_project_items.splice(index, 1);
    },
    setupTitle() {
      this.$title = `Projects`;
    },
    addProject() {
      this.addProjectSheet = true;
    },
    submitProject() {
      this.$refs.addProjectForm.validate();
      if (this.valid) {
        this.loading = true;
        let project = _.cloneDeep(this.newProject);
        let _mDs = [];
        project.created_by = `${this.user.first_name} ${this.user.last_name}`;
        project.account = `${this.user.account}`;
        project.identifier = this.generateUUID();
        this.newProject.calc_project_items.forEach((item) => {
          let md = {
            identifier: this.generateUUID(),
            name: this.appConfig.texts.projects.newProject.mdValue,
            description: "",
            type: 9000,
            color: "",
            product: item.product,
            ampere: item.ampere,
            preAmp: item.preAmp,
            shortCircuitResistance: item.shortCircuitResistance,
            valid: false,
            grid: this.newProject.grid,
            _items: [],
          };
          _mDs.push(md);
        });
        project.project_items = JSON.stringify(_mDs);
        delete project.calc_project_items;
        // console.log(project);
        this.$store
          .dispatch("liDehnProject/CREATE_COLLECTION_ITEM", {
            object: "dehn_projects",
            item: project,
            user: `${this.user.first_name} ${this.user.last_name}`,
          })
          .then((response) => {
            this.getProjects();
            this.addProjectSheet = false;
            this.$refs.addProjectForm.resetValidation();
            this.snackbarText = this.appConfig.texts.projects.submitText;
            this.snackbar = true;

            setTimeout(() => {
              this.snackbarText = "";
            }, this.snackbarTimeout);

            setTimeout(() => {
              this.loading = false;
              this.$router.push(`/project/${response[0].identifier}`);
            }, 600);

            setTimeout(() => {
              this.newProject = {
                created_by: `${this.user.first_name} ${this.user.last_name}`,
                account: `${this.user.account}`,
                identifier: "",
                name: "",
                external: "",
                grid: "",
                reference_number: "",
                lpl: "",
                calc_project_items: [
                  {
                    identifier: this.generateUUID(),
                    name: "",
                    subdividers: 0,
                    critendgroups: 0,
                  },
                ],
              };
            }, 500);
          });
      }
    },
    removeProject(project) {
      this.$store
        .dispatch("liDehnProject/REMOVE_COLLECTION_ITEM", {
          object: "dehn_projects",
          item: project,
        })
        .then(() => {
          this.getProjects();
        });
    },
  },
};
</script>
<style lang="scss"></style>
